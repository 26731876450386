<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="enttarsale">
        <div class="con">
            <ul class="con_tit">
                <li v-for="(i,index) in chakan_list" :key="index" :class="chakan_num==index?'zise_num':''" @click="xuanze_zhanshi(index)">{{i}}</li>
            </ul>
            <ul class="con_tit_xuan">
                <li v-for="(i,index) in biaoti_list" :class="an_shenme==index?'zise':''" @click='an_shenme=index'  :key="index">{{i}}</li>
            </ul>
            <div class="con_box">
                <div class="con_box_left" v-if="an_shenme==0">
                    <!-- 
                        check-strictly -->
                    <el-tree
                        :data="faqi_ren_list"
                        class="tree_bumen"
                        show-checkbox
                        default-expand-all
                        node-key="id"
                        ref="all_staff_tree"
                        highlight-current
                        :default-checked-keys='gouxuan_key'
                        @check-change="xuanze_renyuan"
                        :props="defaultProps">
                    </el-tree>
                </div>
                <div class="con_box_left" v-if="an_shenme==1">
                    <div class="xuanze_sousuo">
                        <i class="el-icon-search"></i>
                        <input type="text" placeholder="搜索" @keyup="keyu_jue" v-model="juese_sousuo">
                    </div>
                    <div class="mianbaoxie">
                        <div v-for="(i,index) in mianbao_list_jue" :key='index'  @click="mianbao_dian_jue(i,index)">
                            <p :class="mianbao_list_jue.length-1==index?'p1':'p2'">{{i.name}}</p>
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <!-- <div class="quanxuan" v-if="qiye_juesery_list.length!=0">
                        <img class="img2" @click="dianji_quanxuan_jue" :src="quanxuan_jue?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
                        <span>全选</span>
                    </div> -->
                    <div class="nei_box">
                        <div class="liebiao">
                            <ul>
                                <li v-for="(i,index) in qiye_juese_list" :key="index" @click="dianji_bumen_jue(i)">
                                    <p style="margin-left:0.16rem;">{{i.role_group_name}}</p>
                                    <!-- <img class="img1" :src="i.child_flag=='0'?require('../assets/chazi.png'):require('../assets/chazi_ok.png')" alt=""> -->
                                </li>
                            </ul>
                            <ul>
                                <li v-for="(i,index) in qiye_juesery_list" :key="index" style='justify-content:left;margin-left:-0.07rem;'>
                                    <!-- <input ref="have_jue" type="checkbox" @click="dianji_xuanren_jue(i,$event,index)"> -->
                                    <img class="img2" @click="dianji_xuanren_jue(i,index)" :src="i.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
                                    <p>{{i.role_name}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="con_box_right">
                    <ul>
                         <!-- @click="dianji_danxuan(i)" -->
                        <li v-for="(i,index) in renyuan_list" :class="Number(index)%2!=0?'huise':''" :key="index">
                            <!-- <img :src="i.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt=""> -->
                            <p class="touxiang">{{(i.role_name? i.role_name : i.label).split('')[0]}}</p>
                            <div>
                                <p>{{i.role_name? i.role_name : i.label}}</p>
                                <!-- <p>{{i.dept_name}}</p> -->
                            </div>
                            <!-- <i class="el-icon-error" @click="shanchu_ren(i)"></i> -->
                        </li>
                    </ul>
                </div>
            </div>
            <p class="con_foot">
                <span @click="dianji_baocun">保存</span>
            </p>
        </div>
        <!-- <div class="beijing" v-if="kejian">
            <div class="beijing_box">
                <p class="beijing_tit">模块是否全部可见</p>
                <ul>
                    <li v-for="(i,index) in renyuan_list" :class="Number(index)%2==0?'huise':''" :key="index">
                        <div class='ren_left'>
                            <p class="touxiang">{{i.label[0]}}</p>
                            <div>
                                <p>{{i.label}}</p>
                            </div>
                        </div>
                        <p class='ren_right'><span @click="i.shuzhi=ind" v-for="(it,ind) in shi_or_fo" :key="ind"><img :src="i.shuzhi==ind?require('../../assets/red_quan.png'):require('../../assets/ccc_quan.png')">{{it}}</span></p>
                    </li>
                </ul>
                <p class="beijing_foot">
                    <span @click="dianji_baocun">保存</span>
                    <span @click="kejian=false">取消</span>
                </p>
            </div>
        </div> -->
    </div>
</template>

<script>
import { query_aim_module_power_detail, set_aim_module_power, query_dept_staff_tree_aim_not, query_ent_dept_list_aim_not, query_role_info_list, query_module_power_detail, set_module_power, query_role_group_info_list, query_dept_staff_tree_aim } from '../../api/api.js'
export default {
  name: 'enttarsale',
  data () {
    return {
      faqi_ren_list: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      renyuan_list: [],
      gouxuan_key: [], // 默认勾选的节点的 key 的数组
      biaoti_list: ['按组织架构', '按角色'],
      an_shenme: '0',
      ids: [],
      type: '0',
      mianbao_list_jue: [
        {
          name: '',
          dept_id: '0'
        }
      ],
      qiye_juese_list: [],
      qiye_juesery_list: [],
      quanxuan_jue: false,
      juese_sousuo: '',

      chakan_list: ['设置查看权限', '设置使用权限'],
      chakan_num: '0',
      shi_or_fo: ['是', '否'],
      kejian: false
    }
  },
  created () {
    this.chakan()
    this.mianbao_list_jue[0].name = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_name
  },
  watch: {
    an_shenme () {
      this.qiehuan_xuanze()
      this.renyuan_list = []
    }
  },
  mounted () {
      
  },
  methods: {
    jichu () {
      query_dept_staff_tree_aim_not({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        if (res.data.code == 10168) {
          const date = JSON.parse(res.data.body.data)
          this.faqi_ren_list = date
          if (this.ids.length != 0) {
            this.$refs.all_staff_tree.setCheckedKeys(this.ids)
            this.renyuan_list = this.getSimpleCheckedNodes(this.$refs.all_staff_tree.store)
          } else {
            this.$refs.all_staff_tree.setCheckedKeys(this.ids)
          }
        } else if (res.data.code == 10169) {}
      })
    },
    chakan () {
      // query_module_power_detail
      query_aim_module_power_detail({
        data: {
          ent_id: this.$ent_id(),
          module_id: '1-2',
          read_flag: Number(this.chakan_num) + 1 + ''
        }
      }).then(res => {
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          if (date != null) {
            let ids = []
            if (date.staff_ids != undefined) {
              ids.push(date.staff_ids)
            }
            if (date.dept_ids != undefined) {
              ids.push(date.dept_ids)
            }
            if (date.role_ids != undefined) {
              ids.push(date.role_ids)
            }
            ids = ids.join(',')
            ids = ids.split(',')
            this.an_shenme = Number(date.type) - 1
            this.type = Number(date.type) - 1
            this.ids = ids
          } else {
            this.ids = []
            this.an_shenme = 0
          }
          setTimeout(() => {
            this.qiehuan_xuanze()
          }, 0)
        } else if (res.data.code == 500) {}
      })
    },
    qiehuan_xuanze () {
      if (this.an_shenme == 0) {
        this.jichu()
      } else {
        query_role_group_info_list({
          data: {
            ent_id: this.$ent_id(),
            active: '1'
          }
        }).then(res => {
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            for (let i = 0; i < date.length; i++) {
              query_role_info_list({
                data: {
                  ent_id: this.$ent_id(),
                  role_group_id: i.dept_id
                }
              }).then(ras => {
                if (ras.data.code == 200) {
                  const listdata = JSON.parse(ras.data.body.data)
                  listdata.map(item => {
                    this.$set(item, 'zhi', false)
                    this.$set(item, 'label', item.role_name)
                    this.$set(item, 'type', '3')
                  })
                  this.ids.map(item => {
                    listdata.map(itm => {
                      if (item == itm.role_id) {
                        this.renyuan_list.push(itm)
                      }
                    })
                  })
                  this.renyuan_list = this.$func.Es5duplicate(this.renyuan_list, 'role_id')
                } else if (ras.data.code == 500) {
                }
              })
            }
          } else if (res.data.code == 500) {
          }
        })
        this.dakai_juese()
      }
    },
    xuanze_renyuan (date) {
      const list = this.$refs.all_staff_tree.getCheckedNodes()
      this.renyuan_list = this.getSimpleCheckedNodes(this.$refs.all_staff_tree.store)
    },
    getSimpleCheckedNodes (store) {
      console.log(store)
      const checkedNodes = []
      const traverse = function (node) {
        const childNodes = node.root ? node.root.childNodes : node.childNodes
        childNodes.forEach(child => {
          if (child.checked) {
            checkedNodes.push(child.data)
          }
          if (child.indeterminate) {
            traverse(child)
          }
        })
      }
      traverse(store)
      return checkedNodes
    },
    shanchu_ren (i) {
      const list = this.renyuan_list
      const list2 = []
      list.map(item => {
        if (item.id != i.id) {
          list2.push(item)
        }
      })
      this.$refs.all_staff_tree.setCheckedKeys([])
    },
    dianji_xia_yibu () {
      this.kejian = true
      this.renyuan_list.forEach(item => {
        this.$set(item, 'shuzhi', '1')
      })
    },
    dianji_baocun () {
      console.log(this.renyuan_list)
      if (this.renyuan_list.length != 0) {
        const bumen_ids = []
        const renyuan_ids = []
        const juese_ids = []
        for (let i = 0; i < this.renyuan_list.length; i++) {
          if (this.renyuan_list[i].type == 1) {
            bumen_ids.push(this.renyuan_list[i].id)
          } else if (this.renyuan_list[i].type == 2) {
            renyuan_ids.push(this.renyuan_list[i].id)
          } else if (this.renyuan_list[i].role_group_name == "默认角色组") {
            juese_ids.push(this.renyuan_list[i].role_id)
          }
        }
        // set_module_power
        set_aim_module_power({
          data: {
            ent_id: this.$ent_id(),
            module_id: '1-2',
            type: Number(this.an_shenme) + 1 + '',
            staff_ids: renyuan_ids.length != 0 ? renyuan_ids.join(',') : null,
            dept_ids: bumen_ids.length != 0 ? bumen_ids.join(',') : null,
            role_ids: juese_ids.length != 0 ? juese_ids.join(',') : null,
            read_flag: Number(this.chakan_num) + 1 + ''
          }
        }).then(res => {
          if (res.data.code == 200) {
            let ids = []
            if (renyuan_ids.length != 0) {
              ids.push(renyuan_ids)
            }
            if (bumen_ids.length != 0) {
              ids.push(bumen_ids)
            }
            if (juese_ids.length != 0) {
              ids.push(juese_ids)
            }
            ids = ids.join(',')
            ids = ids.split(',')
            this.ids = ids
            this.$message({
              message: '保存成功',
              type: 'success'
            })
          } else if (res.data.code == 500) {}
        })
      } else {
        set_aim_module_power({
          data: {
            ent_id: this.$ent_id(),
            module_id: '1-2',
            type: Number(this.an_shenme) + 1 + '',
            read_flag: Number(this.chakan_num) + 1 + ''
          }
        }).then(res => {
          if (res.data.code == 200) {
            this.$message({
              message: '保存成功',
              type: 'success'
            })
          } else if (res.data.code == 500) {}
        })
      }
    },
    dakai_juese () {
      this.mianbao_list_jue.splice(1, this.mianbao_list_jue.length)
      this.juese_liebiao()
    },
    dianji_xuanren_jue (i, index) {
      i.zhi = !i.zhi
      console.log(i.zhi)
      if (i.zhi) {
        this.renyuan_list.push(i)
        console.log(this.renyuan_list)
      } else {
        console.log(1)
        for (let a = 0; a < this.renyuan_list.length; a++) {
          if (this.renyuan_list[a].role_id == i.role_id) {
            this.renyuan_list.splice(a, 1)
          }
        }
      }
    },
    // 点击部门
    dianji_bumen_jue (i) {
      this.qiye_juese_list = []
      this.mianbao_list_jue.push({ name: i.role_group_name, dept_id: i.role_group_id })
      query_role_info_list({
        data: {
          ent_id: this.$ent_id(),
          role_group_id: i.role_group_id
        }
      }).then(ras => {
        if (ras.data.code == 200) {
          const listdata = JSON.parse(ras.data.body.data)
          listdata.map(item => {
            this.$set(item, 'zhi', false)
            this.$set(item, 'label', item.role_name)
            this.$set(item, 'type', '3')
          })
          this.qiye_juesery_list = listdata
          this.panduan_juese_xuanzhong()
        } else if (ras.data.code == 500) {
        }
      })
    },
    // 点击面包屑进行页面内容切换
    mianbao_dian_jue (i, index) {
      if (this.mianbao_list_jue.length - 1 != index) {
        query_role_group_info_list({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id
          }
        }).then(res => {
          if (res.data.code == 200) {
            var date = JSON.parse(res.data.body.data)
            this.qiye_juese_list = date
            this.mianbao_list_jue.splice(index + 1, this.mianbao_list_jue.length)
            query_role_info_list({
              data: {
                ent_id: this.$ent_id(),
                role_group_id: this.mianbao_list_jue[this.mianbao_list_jue.length - 1].dept_id
              }
            }).then(ras => {
              if (ras.data.code == 200) {
                const listdata = JSON.parse(ras.data.body.data)
                listdata.map(item => {
                  this.$set(item, 'zhi', false)
                  this.$set(item, 'label', item.role_name)
                  this.$set(item, 'type', '3')
                })
                this.qiye_juesery_list = listdata
              } else if (ras.data.code == 10150) {
              }
            })
          } else if (res.data.code == 500) {
          }
        })
      }
    },
    keyu_jue () {
      query_role_info_list({
        data: {
          ent_id: this.$ent_id(),
          role_name: this.juese_sousuo
        }
      }).then(res => {
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          this.qiye_juesery_list = date
        } else if (res.data.code == 10028) {
        }
      })
    },
    // 角色列表
    juese_liebiao () {
      query_role_group_info_list({
        data: {
          ent_id: this.$ent_id(),
          active: '1'
        }
      }).then(res => {
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          this.qiye_juese_list = date
        } else if (res.data.code == 500) {
        }
      })
      query_role_info_list({
        data: {
          ent_id: this.$ent_id(),
          role_group_id: this.mianbao_list_jue[this.mianbao_list_jue.length - 1].dept_id
        }
      }).then(ras => {
        if (ras.data.code == 200) {
          const listdata = JSON.parse(ras.data.body.data)
          listdata.map(item => {
            this.$set(item, 'zhi', false)
            this.$set(item, 'label', item.role_name)
            this.$set(item, 'type', '3')
          })
          this.qiye_juesery_list = listdata
        } else if (ras.data.code == 500) {
        }
      })
    },
    panduan_juese_xuanzhong () {
      for (let i = 0; i < this.renyuan_list.length; i++) {
        for (let a = 0; a < this.qiye_juesery_list.length; a++) {
          if (this.renyuan_list[i].role_id == this.qiye_juesery_list[a].role_id) {
            this.qiye_juesery_list[a].zhi = true
          }
        }
      }
    },
    // 点击全选角色
    dianji_quanxuan_jue () {

    },
    xuanze_zhanshi (index) {
      this.chakan_num = index
      this.chakan()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.enttarsale{
    height:100%;
    width:100%;
    .con{
        padding: 0.33rem;
        .con_tit{
            font-size: 0.12rem;
            color:#444;
            margin-bottom: 0.1rem;
            display: flex;
            align-items: center;
            li{
                margin-right:0.1rem;
                cursor: pointer;
            }
        }
        .zise_num{
            color:rgb(154, 132, 219);
        }
        .con_tit_xuan{
            display: flex;
            align-items: center;
            li{
                padding: 0 0.12rem;
                border-radius:0.02rem;
                color:#888888;
                border:0.01rem solid #A9A9A9;
                text-align: center;
                line-height: 0.25rem;
                font-size: 0.12rem;
                background: #fff;
                border-radius: 0.03rem;
                cursor:pointer;
                margin-right: 0.1rem;
            }
            .zise{
                background:rgb(154, 132, 219);
                color:#fff;
            }
        }
        .con_box{
            display: flex;
            align-items: center;
            .con_box_left,.con_box_right{
                width:50%;
                border:0.01rem solid #ccc;
                height:430px;
                overflow-y:auto;
                &::-webkit-scrollbar {
                    width: 0.2rem;
                    height: 0.08rem;
                }
                
                &::-webkit-scrollbar-thumb {
                    border-radius: 0.12rem;
                    border: 0.06rem solid rgba(0, 0, 0, 0);
                    box-shadow: 0.08rem 0 0 #A5ADB7 inset;
                }
                
                &::-webkit-scrollbar-thumb:hover {
                    box-shadow: 0.08rem 0 0 #4A4A4A inset;
                }
                .xuanze_sousuo{
                    position: relative;
                    i{
                        position: absolute;
                        top:0.11rem;
                        left: 0.67rem;
                    }
                    input{
                        height:0.3rem;
                        width:2.74rem;
                        border:0.01rem solid #ccc;
                        background:#fff;
                        padding: 0 0.33rem;
                        margin: 0 auto;
                        display: block;
                        margin-top: 0.15rem;
                        border-radius:0.02rem;
                    }
                }
                .mianbaoxie{
                    display: flex;
                    align-items: center;
                    justify-content: left;
                    margin-bottom: 0.1rem;
                    margin-left: 0.23rem;
                    margin-top:0.12rem;
                    div{
                        display: flex;
                        align-items: center;
                        cursor:pointer;
                        p{
                            font-size: 0.14rem;
                        }
                        .p2{
                            color:#F96C6C;
                        }
                        .p1{
                            color:#1A2533;
                        }
                    }
                }
                .quanxuan{
                    display: flex;
                    align-items: center;
                    margin-left: 0.24rem;
                    input{
                        -webkit-appearance: none;
                        width: 0.18rem!important;
                        height: 0.17rem!important;
                        display: inline-block;
                        margin: -0.03rem 0.05rem 0 0!important;
                        cursor: pointer;
                        vertical-align: middle;
                        background:url(../../assets/noccc.png) no-repeat;
                        &:checked {
                            background: url(../../assets/okblue.png) no-repeat;
                        }
                        outline-color:none;
                        outline-style :none;
                        outline-width:none;
                    }
                    img{
                        height:0.16rem;
                        width:0.16rem;
                        margin-right: 0.08rem;
                        cursor: pointer;
                    }
                    span{
                        color:#1A2533;
                        font-size: 0.12rem;
                    }
                }
                .nei_box{
                    width:3.45rem;
                    margin: 0 auto;
                    max-height: 2.4rem;
                    overflow:auto;
                    // overflow-y: hidden;
                    overflow-y: scroll;
                    &::-webkit-scrollbar {
                        width: 0.19rem;
                        height: 0;
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 0.12rem;
                        border: 0.06rem solid rgba(0, 0, 0, 0);
                        box-shadow: 0.08rem 0 0 #A5ADB7 inset;
                    }

                    &::-webkit-scrollbar-thumb:hover {
                        box-shadow: 0.08rem 0 0 #4A4A4A inset;
                    }
                    .liebiao{
                        width:3.25rem;
                        margin: 0 auto;
                        ul{
                            li{
                                display: flex;
                                height:0.3rem;
                                justify-content: space-between;
                                align-items: center;
                                color:#1A2533;
                                font-size: 0.12rem;
                                padding-left: 0.1rem;
                                cursor:pointer;
                                &:hover{
                                    background: #eee;
                                }
                                .img1{
                                    height:0.11rem;
                                    width:0.2rem;
                                }
                                input{
                                    -webkit-appearance: none;
                                    width: 0.18rem!important;
                                    height: 0.17rem!important;
                                    display: inline-block;
                                    margin: -0.03rem 0.05rem 0 0!important;
                                    cursor: pointer;
                                    vertical-align: middle;
                                    background:url(../../assets/noccc.png) no-repeat;
                                    &:checked {
                                        background: url(../../assets/okblue.png) no-repeat;
                                    }
                                    outline-color:none;
                                    outline-style :none;
                                    outline-width:none;
                                }
                                .img2{
                                    height:0.16rem;
                                    width:0.16rem;
                                    margin-right: 0.08rem;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
            .con_box_right{
                ul{
                    li{
                        font-size: 0.12rem;
                        padding:0.1rem 0 0.07rem 0.2rem;
                        color:#444;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        margin: 0.06rem 0;
                        position: relative;
                        img{
                            height:0.14rem;
                            width:0.14rem;
                            margin-right: 0.06rem;
                        }
                        .touxiang{
                            height:0.4rem;
                            width:0.4rem;
                            line-height: 0.4rem;
                            background: #1890ff;
                            color:#fff;
                            font-size: 0.2rem;
                            text-align: center;
                            font-family: NSimSun;
                            border-radius: 50%;
                            margin-right: 0.08rem;
                        }
                        div{
                            p{
                                font-size: 0.12rem;
                                &:nth-child(1){
                                    color:#444;
                                }
                                &:nth-child(2){
                                    color:#999;
                                }
                            }
                        }
                        i{
                            position: absolute;
                            right:0.2rem;
                            font-size: 0.2rem;
                            cursor: pointer;
                            top:0.2rem;
                        }
                    }
                    .huise{
                        background: #f6f8f9;
                    }
                }
            }
        }
        .con_foot{
            display: flex;
            justify-content: center;
            margin-top: 0.1rem;
            span{
                padding: 0.08rem 0.13rem;
                border-radius: 0.04rem;
                background: rgb(154, 132, 219);
                font-size: 0.14rem;
                color:#fff;
                display: inline-block;
                cursor: pointer;
            }
        }
    }
    .beijing{
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
        margin: 0;
        z-index:100;
        background: rgba(0,0,0,0.5);
        .beijing_box{
            height:457px;
            width:432px;
            border-radius: 0.03rem;
            background: #fff;
            position: relative;
            top:2rem;
            left:50%;
            margin-left: -215px;
            .beijing_tit{
                text-align: center;
                font-size: 0.16rem;
                line-height: 0.3rem;
            }
            ul{
                height:3.6rem;
                overflow-y:auto;
                &::-webkit-scrollbar {
                    width: 0.2rem;
                    height: 0.08rem;
                }
                
                &::-webkit-scrollbar-thumb {
                    border-radius: 0.12rem;
                    border: 0.06rem solid rgba(0, 0, 0, 0);
                    box-shadow: 0.08rem 0 0 #A5ADB7 inset;
                }
                
                &::-webkit-scrollbar-thumb:hover {
                    box-shadow: 0.08rem 0 0 #4A4A4A inset;
                }
                li{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .ren_left{
                        font-size: 0.12rem;
                        padding:0.1rem 0 0.07rem 0.2rem;
                        color:#444;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        margin: 0.06rem 0;
                        position: relative;
                        img{
                            height:0.14rem;
                            width:0.14rem;
                            margin-right: 0.06rem;
                        }
                        .touxiang{
                            height:0.4rem;
                            width:0.4rem;
                            line-height: 0.4rem;
                            background: #1890ff;
                            color:#fff;
                            font-size: 0.2rem;
                            text-align: center;
                            font-family: NSimSun;
                            border-radius: 50%;
                            margin-right: 0.08rem;
                        }
                        div{
                            p{
                                font-size: 0.12rem;
                                &:nth-child(1){
                                    color:#444;
                                }
                                &:nth-child(2){
                                    color:#999;
                                }
                            }
                        }
                        i{
                            position: absolute;
                            right:0.2rem;
                            font-size: 0.2rem;
                            cursor: pointer;
                            top:0.2rem;
                        }
                    }
                    .ren_right{
                        display: flex;
                        align-items: center;
                        img{
                            height:0.16rem;
                            width:0.16rem;
                            margin-right:0.06rem;
                        }
                        span{
                            margin-right: 0.1rem;
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                        }
                    }
                    
                }
                .huise{
                    background: #f6f8f9;
                }
            }
            .beijing_foot{
                display: flex;
                justify-content: center;
                margin-top: 0.1rem;
                span{
                    padding: 0.08rem 0.13rem;
                    border-radius: 0.04rem;
                    background: rgb(154, 132, 219);
                    font-size: 0.14rem;
                    color:#fff;
                    display: inline-block;
                    cursor: pointer;
                    &:nth-child(2){
                        background: #ccc;
                    }
                    margin: 0 0.1rem;
                }
            }
        }
    }
}
</style>
